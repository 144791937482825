<template>
    <div>
        <multiselect
            :placeholder="$t('select')"
            v-model="selected"
            :options="options"
            :class="validateError != '' ? 'box-border-color' : ''"
            @input="handleInput($event, multiple)"
            label="text"
            track-by="value"
            :multiple="multiple"
            :close-on-select="!multiple"
            :clear-on-select="!multiple"
            :select-label="''"
            :selected-label="''"
            :deselect-label="''"
        >
            <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
                v-if="multiple"
            >
                <span class="multiselect__single" v-if="values.length && !isOpen">
	                {{ translateNSelected(values) }}
                </span>
            </template>
            <span slot="noOptions">
                {{ $t("no_options") }}
            </span>
            <span slot="noResult">
                {{ $t("no_result") }}
            </span>
        </multiselect>
        <span
            class="invalid-feedback-custom"
            v-show="validateError"
            v-html="validateError"
        >
        </span>
    </div>
</template>

<script>
// Helpers
import translateNSelected from "@/helpers/translateNSelected";
import handleInput from "@/helpers/handleInput";
import setSelected from "@/helpers/setSelected";

// Services
import SlotTypeService from "@/services/SlotTypeService";

export default {
    props: {
        value: {
            default: null,
        },
        multiple: {
            default: false,
        },
        validateError: {
            type: String,
            default: "",
        },
        nonFilter: {
            type: Array,
            default: null,
        },
        onlyFilter: {
            type: Array,
            default: null,
        },
    },
    watch: {
        value: function (newValue) {
            this.selected = this.setSelected(newValue, this.options, this.multiple)
        }
    },
    data() {
        return {
            selected: null,
            options: [],
        };
    },
    created() {
        this.getOptions();
    },
    methods: {
        translateNSelected: translateNSelected,
        handleInput: handleInput,
        setSelected: setSelected,
        getOptions() {
            const config = {
                params: {
                    sort: "code",
                    limit: -1,
                },
            };
            SlotTypeService.getAll(config)
                .then((response) => {
                    const data = response.data.data;
                    data.map((item) => {
                        item.value = item.code;
                        item.text = this.getLocaleText(item, "name");
                        if (this.nonFilter == null && this.onlyFilter == null) {
                            this.options.push(item);
                        } else {
                            if(this.nonFilter != null) {
                                if (!this.nonFilter.includes(item.filter)) {
                                    this.options.push(item);
                                }
                            } else if(this.onlyFilter != null) {
                                if (this.onlyFilter.includes(item.code)) {
                                    this.options.push(item);
                                }
                            }
                        }
                    });
                })
                .then(() => {
                    this.selected = this.setSelected(this.value, this.options, this.multiple)
                })
        },
        getItem(id){
            let found=null
            this.options.forEach((item)=>{
                if(item.value===id){
                    found = item;
                }
            });
            return found;
        }
    }
}
</script>
