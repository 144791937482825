<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color' : ''"
			@input="handleInput($event)"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true"
		>
			<span slot="noOptions">
				{{ $t("no_options") }}
			</span>
			<span slot="noResult">
                {{ $t("no_result") }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom"
		      v-show="validateError"
		      v-html="validateError"
		/>
	</div>
</template>

<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	// Services
	import ElectivePoolService from "@/services/ElectivePoolService";

	// Other
	import qs from "qs";

	export default {
		props: {
			programCode: {
				type: String,
				default: null,
			},
			type: {
				type: String,
				default: null,
			},
			value: {
				default: null,
			},
			validateError: {
				type: String,
				default: "",
			},
		},

		data() {
			return {
				selected: null,
				options: [],
			};
		},
		watch: {
			programCode: function () {
				this.getOptions()
			},
			type: function () {
				this.getOptions()
			},
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			},
		},
		created() {
			this.getOptions();
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
			getOptions() {
				this.clearOptions();
				if (this.programCode == null || this.type == null) {
					this.$emit("input", null)
					return;
				}
				let config = {
					params: {
						type: this.type,
						program_code: this.programCode,
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false}),
				};
				ElectivePoolService.getOptions(config)
				                   .then((response) => {
					                   const data = response.data.data;
					                   data.map((item) => {
						                   this.options.push({
							                   value: item.id,
							                   text: this.$i18n.locale == "tr" ? item.name : item.name_en,
						                   });
					                   });
				                   })
				                   .then(() => {
					                   this.selected = this.setSelected(this.value, this.options)
				                   })
			},
			clearOptions() {
				this.selected = null
				this.options = []
			}
		}
	}
</script>
